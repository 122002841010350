import { createI18n } from 'vue-i18n'; 
import { messages } from "@/lang";

const i18n = createI18n(
    { 
        legacy: false,
        fallbackLocale: 'de', 
        locale: navigator.language.split('-')[0], 
        silentFallbackWarn: true, messages, 
        missingWarn: false,
        fallbackWarn: false
    }
);

export default i18n;