<template>
  <div
  >
    <h1 style="margin-top: 10%">badBoard LOGIN</h1>
    <form style="margin: 15%" @submit.prevent="login">
      <v-text-field v-model="input.email" placeholder="email" required></v-text-field>
      <br />
      <br />
      <v-text-field v-model="input.password" placeholder="password" type="password" required></v-text-field>
      <br />
      <br />
      <button type="submit">Login</button>
    </form>
    <v-snackbar v-model="misc['snackbar']" :timeout="2000">
      {{ misc["snackbarText"] }}

      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" color="blue" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import VueJwtDecode from "vue-jwt-decode";

export default {
  name: "Login",
  components: {},
  data() {
    return {
      input: {
        email: "",
        password: "",
      },
      misc: {
        snackbarText: "",
        hostname: location.host,
        snackbar: false,
      },
      showError: false,
    };
  },
  methods: {
    ...mapMutations(["setEmail", "setToken"]),
    async login(e) {
      e.preventDefault();
      const response = await fetch(
        location.protocol + "//" + this.misc.hostname + "/api/auth/login",
        //location.protocol + "//localhost:7001/api/auth/login",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: this.input.email,
            password: this.input.password,
          }),
        }
      );
      const { email, token, exp } = await response.json();
      console.log(email, token);
      this.setEmail(email);
      this.setToken(token);
      this.$store.state.exp = exp
      console.log(exp)
      window.localStorage.setItem(
          "token",token
        );
      window.localStorage.setItem(
          "exp",exp
        );
          if (this.$store.state.token) {
          this.$store.state.tokenDecoded = VueJwtDecode.decode(
      this.$store.state.token
    );
    }
      this.$router.push("/");
    },
    checkHost: function () {
      console.log(location.host);
      if (location.host == "badboard.badcloud.eu" ||  location.host == "test.badcloud.eu") {
        this.$store.state.globalData.hostname = location.host;
        this.misc.hostname = this.$store.state.globalData.hostname;
      } else {
        this.$store.state.globalData.hostname = "192.168.0.106:7000";
        this.misc.hostname = this.$store.state.globalData.hostname;
      }
      console.log(this.$store.state.globalData.hostname);
    },
  },
  mounted() {
    //this.setDefaultValues(),
    this.checkHost();
    this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
  },
};
</script>

<style scoped>
* {
  box-sizing: border-box;
}
label {
  padding: 12px 12px 12px 0;
  display: inline-block;
}
button[type="submit"] {
  background-color: #4caf50;
  color: white;
  padding: 12px 20px;
  cursor: pointer;
  border-radius: 30px;
}
button[type="submit"]:hover {
  background-color: #45a049;
}

input {
  color: gray;
  margin: 5px;
  box-shadow: 0 0 15px 4px rgba(0, 0, 0, 0.06);
  padding: 10px;
  border-radius: 30px;
}
#error {
  color: red;
}
</style>