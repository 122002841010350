
import { createVuetify } from 'vuetify';
import 'vuetify/styles'

import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import * as labsComponents from 'vuetify/labs/components'
import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader



import de from 'vuetify/lib/locale/de'
import en from 'vuetify/lib/locale/en'
import fr from 'vuetify/lib/locale/fr'
import i18n from "@/plugins/i18n";

const locales = {
    de: de,
    en: en,
    fr: fr,
    en_US: en
}

const current = navigator.language.split('-')[0]

console.log(current,navigator.language)


const vuetify = createVuetify({
    components,
    directives, 
    labsComponents,
    lang: {
        t: (key, ...params) => i18n.global.t(key, params),
    },
    locale: {
        locales,
        current,
    },
    icons: {
        iconfont: 'mdi',
    },
    theme: {
        defaultTheme: 'dark'
    }
});



export default vuetify;
