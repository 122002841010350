import { createWebHashHistory, createRouter } from "vue-router";
import Home from "../components/Home";
import Sensoren from "../components/Sensoren";
import Zaehler from "../components/Zaehler";
import Chichi from "../components/Chichi";
import Music from "../components/Music";
import Login from "../components/Login";
import Users from "../components/Users";
import Flowers from "../components/Flowers";
import NotFound from "../components/NotFound";


const routes = [
    {
        path: "/",
        name: "Home",
        component: Home,
        meta: {
            requiresAuth: true
          }
    },
    {
        path: "/login",
        name: "Login",
        component: Login,
    },
    {
        path: "/sensoren",
        name: "sensoren",
        component: Sensoren,
        meta: {
            requiresAuth: true
          }
    },
    {
        path: "/users",
        name: "Users",
        component: Users,
        meta: {
            requiresAuth: true
          }
    },
    {
        path: "/zaehler",
        name: "zaehler",
        component: Zaehler,
        meta: {
            requiresAuth: true
          }
    },
    {
        path: "/chichi",
        name: "chichi",
        component: Chichi,
        meta: {
            requiresAuth: true
          }
    },
    {
        path: "/music",
        name: "music",
        component: Music,
        meta: {
            requiresAuth: true
          }
    },
    {
      path: "/flowers",
      name: "flowers",
      component: Flowers,
      meta: {
          requiresAuth: true
        }
  },
    { 
      path: '/:pathMatch(.*)*', 
      name: 'not-found', 
      component: NotFound ,
      meta: {
        requiresAuth: true
      }
    }
];

const router = new createRouter({
    history: createWebHashHistory(),
        base: window.location.pathname,
    routes
});

export default router;
