<template>
  <div>
    <h1 style="margin: 2%">Hey {{$store.state.tokenDecoded.user}}</h1>
    <form style="margin: 10%" @submit.prevent="addUser"  v-if="$store.state.tokenDecoded.group == 'admin'">
      <v-text-field
        v-model="input.username"
        placeholder="username"
        required
      ></v-text-field>
      <br />
      <br />
      <v-text-field
        v-model="input.email"
        placeholder="email"
        required
      ></v-text-field>
      <br />
      <br />
      <v-text-field
        v-model="input.password"
        placeholder="password"
        type="password"
        required
      ></v-text-field>
      <br />
      <br />
      <button type="submit">Add User</button>
    </form>
    <v-table  v-if="users && $store.state.tokenDecoded.group == 'admin'">
      <template v-slot:default>
        <thead>
          <tr>
            <th>Name</th>
            <th>email</th>
            <th>password</th>
            <th>group</th>
            <th>last</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in users" :key="item.name">
            <td>{{ item.name }}</td>
            <td>{{ item.email }}</td>
            <td>
              <v-text-field
                v-model="item.password"
                placeholder="password"
              ></v-text-field>
              <v-btn
                @click="changePassword(item.name, item.email, item.password)"
              >
                change
              </v-btn>
            </td>
            <td>{{ item.group }}</td>
            <td>{{ new Date(item.lastLogin*1000).toLocaleString() }}</td>
          </tr>
        </tbody>
      </template>
    </v-table>
    <v-snackbar v-model="misc['snackbar']" :timeout="2000">
      {{ misc["snackbarText"] }}

      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" color="blue" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>





<script>
export default {
  name: "Users",
  data: () => ({
    input: {
      username: "",
      password: "",
      email: "",
    },
    users: null,
    user: null,
    misc: {
      snackbarText: "",
      hostname: location.host,
      snackbar: false,
    },
  }),
  computed: {
    computedCols() {
      if (this.$vuetify.display.xs) {
        return "6";
      } else if (this.$vuetify.display.sm) {
        return "4";
      } else if (this.$vuetify.display.md) {
        return "4";
      } else if (this.$vuetify.display.lg) {
        return "2";
      } else if (this.$vuetify.display.xl) {
        return "2";
      } else {
        return "1";
      }
    },
    computedWidth() {
      console.log(this.$vuetify.display.name);
      switch (this.$vuetify.display.name) {
        case "xs":
          return 190;
        case "sm":
          return 250;
        case "md":
          return 250;
        case "lg":
          return 250;
        case "xl":
          return 250;
        default:
          return 250;
      }
    },
  },
  methods: {
    fetchUsers: function () {
      const requestOptions = {
        method: "GET",
        headers: {
          "x-access-token": this.$store.state.token,
          "Content-Type": "application/json",
        },
      };
      fetch(
        location.protocol + "//" + this.misc.hostname + "/api/auth/user",
        //location.protocol + "//localhost:7001/api/auth/user",
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          this.users = data["users"];
          console.log(this.users);
          //this.fetchValues();
        })
        .catch((e) => {
          this.misc["snackbarText"] = "Error " + e;
          this.misc["snackbar"] = true;
        });
    },
    addUser: function () {
      var bodyValues = {
        name: this.input.username,
        email: this.input.email,
        password: this.input.password,
      };
      console.log(bodyValues);
      const requestOptions = {
        method: "POST",
        headers: {
          "x-access-token": this.$store.state.token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(bodyValues),
      };
      fetch(
        location.protocol + "//" + this.misc.hostname + "/api/auth/signup",
        //location.protocol + "//localhost:7001/api/auth/signup",
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          this.user = data;
          console.log(this.user);
          this.fetchUsers();
          this.misc["snackbarText"] = "ok";
          this.misc["snackbar"] = true;
        })
        .catch((e) => {
          this.misc["snackbarText"] = "Error " + e;
          this.misc["snackbar"] = true;
        });
    },
    changePassword: function (name, email, password) {
      console.log(name, email, password);
      var bodyValues = {
        name: name,
        email: email,
        password: password,
      };
      console.log(bodyValues);
      const requestOptions = {
        method: "POST",
        headers: {
          "x-access-token": this.$store.state.token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(bodyValues),
      };
      fetch(
        location.protocol +
          "//" +
          this.misc.hostname +
          "/api/auth/changepassword",
        //location.protocol + "//localhost:7001/api/auth/signup",
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          this.user = data;
          console.log(this.user);
          this.fetchUsers();
          this.misc["snackbarText"] = "ok";
          this.misc["snackbar"] = true;
        })
        .catch((e) => {
          this.misc["snackbarText"] = "Error " + e;
          this.misc["snackbar"] = true;
        });
    },
    checkHost: function () {
      this.misc.hostname = this.$store.state.globalData.hostname;
      console.log(this.misc.hostname);
    },
  },
  watch: {
    sliderValueWatch: function (a, b) {
      console.log(a, b, this.misc.switchValue);
      //this.misc.switchValue = a;
    },
  },
  mounted() {
    this.checkHost();
    this.fetchUsers();
    //this.fetchDevices();
    //(this.intervalid1 = setInterval(this.fetchValues, 2000));
  },
  components: {},
};
</script>



<style scoped>
#ifrm {
  border: 0;
}
</style>
