import { createApp } from 'vue';

import App from './App.vue'
import './registerServiceWorker'


import i18n from './plugins/i18n'
import vuetify from './plugins/vuetify'
import { store } from './store/store'
import router from './router'

const app = createApp(App);

app.use(i18n);
app.use(vuetify);
app.use(store);
app.use(router);
app.mount('#app');
