<template>
  <div class="LocaleChanger">
    <v-menu transition="slide-y-transition" bottom>
      <template v-slot:activator="{ props }">
        <div v-bind="props" class="current">
          <LangFlag :iso="langs[current]" :title="prettifyLocale(langs[current]) || langs[current]"/>
        </div>
      </template>
      <v-list class="LocaleChangerMenu">
        <v-list-item v-for="(lang, i) in langs" :key="i" :name="lang" @click="changeLang(lang, i)">
          <v-list-item>
            <LangFlag :iso="lang"/>
            <span class="lang-select">{{ prettifyLocale(lang) || lang }}</span>
          </v-list-item>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import {languages} from '@/lang'
// language flag comes from: https://vuejsexamples.com/vue-component-which-shows-the-flag-of-the-country/
import LangFlag from 'vue-lang-code-flags'

export default {
  name: 'LocaleChanger',
  data() {
    return {
      langs: languages,
      current: 0,
      prettyLookUp: {
        "af": "Afrikaans (Afrikaans)",
        "ar": "Arabic (اللغة العربية)",
        "az": "Azerbaijani (Azərbaycan)",
        "bg": "Bulgarian (български)",
        "ca": "Catalan (català)",
        "ckb": "Central Kurdish (کوردی)",
        "cs": "Czech (čeština)",
        "de": "German (Deutsch)",
        "el": "Greek (Ελληνικά)",
        "en": "English (GB)",
        "es": "Spanish (Español)",
        "et": "Estonian (eesti)",
        "fa": "Persian (فارسی)",
        "fi": "Finnish (suomi)",
        "fr": "French (Français)",
        "he": "Hebrew (עברית)",
        "hr": "Croatian (hrvatski jezik)",
        "hu": "Hungarian (magyar)",
        "id": "Indonesian (Indonesian)",
        "it": "Italian (Italiano)",
        "ja": "Japanese (日本語)",
        "ko": "Korean (한국어)",
        "lt": "Lithuanian (lietuvių kalba)",
        "lv": "Latvian (latviešu valoda)",
        "nl": "Dutch (Nederlands)",
        "no": "Norwegian (Norsk)",
        "pl": "Polish (język polski)",
        "pt": "Portuguese (Português)",
        "ro": "Romanian (Română)",
        "ru": "Russian (Русский)",
        "sk": "Slovak (slovenčina)",
        "sl": "Slovene (slovenski jezik)",
        "srCyrl": "Serbian (српски језик)",
        "sv": "Swedish (svenska)",
        "th": "Thai (ไทย)",
        "tr": "Turkish (Türkçe)",
        "uk": "Ukrainian (Українська)",
        "zhHans": "Chinese (中文)",
        "zhHant": "Chinese (中文)"
      }
    }
  },
  methods: {
    changeLang(lang, i) {
      this.$i18n.locale = lang
      this.$vuetify.lang.current = lang
      this.current = i
      this.$emit("isResize", true)  //Resize slider after locale change by emitting isResize and calling onResize() method of v-tabs component in the parent i.e. MainNavigation.vue
    },
    prettifyLocale(locale) {
      return this.prettyLookUp[locale].split(" (")[0];
    }
  },
  created() {
    this.current = this.langs.findIndex(e => e == this.$i18n.locale)
    if (this.current == -1) this.current = 0
  },
  components: {
    LangFlag
  }
}
</script>

<style lang="scss">
.LocaleChanger {
  .current {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  img {
    height: 35px;
  }
}

.LocaleChangerMenu .v-list-item__content {
  justify-content: space-between;
  padding: 6px 0;
  text-transform: uppercase;
  font-size: 10px;
  color: #888888;

  & > * {
    line-height: initial;
    flex: initial;
  }

  span.lang-select {
    margin-left: 10px;
  }

}

.flag-icon.flag-icon-squared {
  height: 22px;
  width: 22px;
  border-radius: 50%;
}
</style>
