<template>
  <div>
    <v-row class="flex justify-center" style="margin: 5px">
      <v-col :cols="computedCols">
        <v-card @keydown="checkEvent">
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value="date"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
          >
            <template v-slot:activator="{ props }">
              <v-text-field
                v-bind="props"
                v-model="date"
                label="Datum"
                prepend-icon="mdi-calendar"
                required
                
              >
              </v-text-field>
            </template>
            <v-date-picker v-model="date">
              <v-btn text color="primary" @click="$refs.menu.save(date)">
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
          <v-form ref="form" style="margin: 5px">
            <v-text-field
              variant="solo"
              required
              type="number"
              prepend-icon="mdi-water-pump"
              v-model.number="meterInput.absWarm"
              label="Heiß Wasser"
            >
            </v-text-field>

            <v-text-field
              variant="solo"
              required
              type="number"
              prepend-icon="mdi-water-pump"
              v-model.number="meterInput.absKalt"
              label="Kalt Wasser"
            ></v-text-field>

            <v-text-field
              variant="solo"
              required
              type="number"
              prepend-icon="mdi-radiator"
              v-model.number="meterInput.absHeizung"
              label="Heizung"
            ></v-text-field>

            <v-text-field
              variant="solo"
              required
              type="number"
              prepend-icon="mdi-flash"
              v-model.number="meterInput.absStrom"
              label="Stromzähler"
            ></v-text-field>
            <v-btn
              color="warning"
              @click="sumbitValues"
              :disabled="misc.submitBtn == false"
            >
              Ok
            </v-btn>
          </v-form>
        </v-card>
      </v-col>
      <v-col :cols="computedCols" @click="timeRangeMeter">
        <h4>Zeitraum</h4>
        <v-col></v-col>
        <v-card-text>
            <v-slider
              v-model="misc.rangeTick"
              :ticks="misc.rangeLabels"
              thumb-label="always"
            
              min="1"
              max="6"
              show-ticks
            >
            <template v-slot:thumb-label="{ value }">
            {{ misc.satisfactionEmojis[Math.min(Math.floor(value ), 9)] }}
          </template>
          
          </v-slider>
            <h4>Die letzen: {{ misc.range[misc.rangeTick -1 ] }} Monate</h4>
          </v-card-text>
      </v-col>
    </v-row>

    <v-row
      v-if="meter"
      class="flex justify-center"
      style="margin-left: 2px"
    >
      <v-col v-for="(item) in filterRel" :key="item.name" :cols="computedCols">
        <v-hover v-if="item.name.includes('rel')" >
          <v-card
            style="margin-top: 20px"
            height="440px"
           
          >
          <v-card-title class="flex justify-center">
              <h4 >
                <span v-if="item.name.includes('Kalt')"> Kaltwasser </span>
                <span v-else-if="item.name.includes('Warm')"> Warmwasser </span>
                <span v-else>
                  {{item.name.substring(3)}}
                </span>
                 </h4>
            </v-card-title>
            <v-card-text class="text-left"> </v-card-text>
            <AreaChart
              :options="charts.lineChartOptions"
              type="line"
              :data="item.metrics.values"
              :chart-id="item.name"
              dataset-id-key="label"
              :style="ChartDataStyles"
            />
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
    <v-snackbar v-model="misc['snackbar']" :timeout="2000">
      {{ misc["snackbarText"] }}

      <template v-slot:action="{ attrs }">
        <v-btn  v-bind="attrs" color="blue" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <canvas ref="canvas"></canvas>
  </div>
</template>


<script setup>
  //import { VDatePicker } from 'vuetify/labs/VDatePicker'



</script>


<script>
//example code
//import { function1 } from '../functions/common-functions';
import { Line as AreaChart } from "vue-chartjs";
import ChartJSPluginDatalabels from "chartjs-plugin-datalabels";


import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  LineElement,
  LinearScale,
  PointElement,
  CategoryScale,
  Filler,
  RadialLinearScale,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  ArcElement,
  LineElement,
  LinearScale,
  PointElement,
  CategoryScale,
  Filler,
  RadialLinearScale,
  ChartJSPluginDatalabels
);

export default {
  name: "Zaehler",
  data: () => ({
    test: 100,
    rws: null,
    meter: [],
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    metrics: null,
    menu: false,
    chartType: "AreaChart",
    charts: {
      pieChartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: true,
            position: "right",
            maxHeight: "auto",
            title: {
              display: true,
              text: "kWh in %",
            },
          },
          datalabels: {
            color: "white",
            textAlign: "center",

            font: {
              //weight: "bold",
              size: 12,
            },
          },
        },
      },
      lineChartOptions: {
        responsive: true,

        maintainAspectRatio: false,
        animations: {
          radius: {
            duration: 400,
            easing: "linear",
          },
        },
        plugins: {
          legend: {
            labels: {
              usePointStyle: true,
            },
          },
          datalabels: {
            color: "white",
            textAlign: "center",
            font: {
              //weight: "bold",
              size: 0,
            },
          },
        },
        scales: {
          A: {
            display: true,
            drawTicks: false,
            position: "left",
            min: 0,
          },
        },
      },
      lineChartOptions2axes: {
        responsive: true,

        maintainAspectRatio: false,
        animations: {
          radius: {
            duration: 400,
            easing: "linear",
          },
        },
        plugins: {
          legend: {
            labels: {
              usePointStyle: true,
            },
          },
          datalabels: {
            color: "white",
            textAlign: "center",
            font: {
              //weight: "bold",
              size: 0,
            },
          },
        },
        scales: {
          A: {
            display: true,
            drawTicks: false,
            position: "left",
          },
          B: {
            display: true,
            drawTicks: false,
            position: "right",
          },
        },
      },
    },
    meterInput: {
      absWarm: null,
      absKalt: null,
      absHeizung: null,
      absStrom: null,
      relHeizung: null,
      relKalt: null,
      relStrom: null,
      relWarm: null,
    },
    misc: {
      snackbarText: "",
      snackbar: false,
      timeRange: undefined,
      range: [100,24,12, 6,3, 2],
      rangeTick:3,
      rangeLabels: ["all","2 Jahre", "1 Jahr", "1/2 Jahr", "3 Monate", "1 Monat"],
      satisfactionEmojis: ['😭', '😢', '☹️', '🙁', '😐', '🙂', '😊', '😁', '😄', '😍'],
      hostname: location.host,
      submitBtn: false,
    },
  }),
  computed: {
    filterRel() {
      let meterRel = this.meter.filter(
        (meter) => meter.name.includes("rel")
      );
      return meterRel;
    },
    ChartDataStyles() {
      return {
        height: `${250}px`,
        width: this.computedWidth,
        position: "relative",
      };
    },
    computedCols() {
      if (this.$vuetify.display.xs) {
        return "12";
      } else if (this.$vuetify.display.sm) {
        return "6";
      } else if (this.$vuetify.display.md) {
        return "6";
      } else if (this.$vuetify.display.lg) {
        return "3";
      } else if (this.$vuetify.display.xl) {
        return "3";
      } else {
        return "1";
      }
    },
    computedWidth() {
      console.log(this.$vuetify.display.name);
      switch (this.$vuetify.display.name) {
        case "xs":
          return 290;
        case "sm":
          return 450;
        case "md":
          return 450;
        case "lg":
          return 450;
        case "xl":
          return 450;
        default:
          return 450;
      }
    },
  },
  filters: {
    test: function (data) {
      console.log(data);
      return data.replace(".", ",");
    },
  },
  methods: {
    checkEvent: function () {
      if (
        this.meterInput.absWarm ||
        this.meterInput.absKalt ||
        this.meterInput.absHeizung ||
        this.meterInput.absStrom
      ) {
        this.misc.submitBtn = true;
      } else {
        this.misc.submitBtn = false;
      }
    },
    sumbitValues: function () {
      this.meterInput.date = this.date + " 12:00:00.000";
      console.log(this.meterInput);
      this.setMeterValues();
    },
    setMeterValues: function () {
      //curl 'http://192.168.0.106:7000/api/meter/set'  -H 'Content-Type: application/json' --data-raw '{"absWarm":"150.10","absKalt":"250.01","absHeizung":"6200","absStrom":"9000.00","relHeizung":null,"relKalt":null,"relStrom":null,"relWarm":null,"date":"2022-01-02 12:00:00.000"}'   --compressed
      const requestOptions = {
        method: "POST",
        headers: {
          "x-access-token": this.$store.state.token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(this.meterInput),
      };
      fetch(
        location.protocol + "//" + this.misc.hostname + "/api/meter/set",
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          this.test = data;
          this.misc["snackbarText"] = "gespeichert";
          this.misc["snackbar"] = true;
          this.fetchMeterValues();
        })
        .catch((e) => {
          this.misc["snackbarText"] = "Error " + e;
          this.misc["snackbar"] = true;
        });
    },
    modifyForChartJs: function (meter) {
        for (const element of meter) {
          if (element.metrics) {
            //chart for power
            if (element.metrics.values) {
              //init chart view
                element.metrics.values.datasets[0] =
                  Object.assign(
                    {
                      data: element.metrics.values.datasets[0].data,
                    },
                    {
                      yAxisID: "A",
                      fill: true,
                      tension: 0.5,
                      borderColor: "#FC2525",
                      borderWidth: 1,
                      pointBorderColor: "white",
                      backgroundColor: this.gradientRed,
                      label: element.metrics.values?.datasets[0].label,
                      pointHoverRadius: 15,
                    }
                  );
              
            }
          }
        
      }
      return meter;
    },
    setChartGradient: function () {

this.gradientRed = this.$refs.canvas
  .getContext("2d")
  .createLinearGradient(0, 0, 0, 450);
this.gradientBlue = this.$refs.canvas
  .getContext("2d")
  .createLinearGradient(0, 0, 0, 450);
this.gradientYellow = this.$refs.canvas
  .getContext("2d")
  .createLinearGradient(0, 0, 0, 450);
this.gradientGreen = this.$refs.canvas
  .getContext("2d")
  .createLinearGradient(0, 0, 0, 450);


this.gradientRed.addColorStop(0.1, "rgba(255, 0,0, 0.5)");
this.gradientRed.addColorStop(0.5, "rgba(255, 0, 0, 0.25)");
this.gradientRed.addColorStop(1, "rgba(145, 67, 204, 0.46)");


this.gradientBlue.addColorStop(0, "rgba(0, 231, 255, 0.9)");
this.gradientBlue.addColorStop(0.5, "rgba(0, 231, 255, 0.25)");
this.gradientBlue.addColorStop(1, "rgba(0, 231, 255, 0)");

this.gradientYellow.addColorStop(0, "rgba(0, 255, 0, 0.9)");
this.gradientYellow.addColorStop(0.5, "rgba(0, 255, 0, 0.25)");
this.gradientYellow.addColorStop(1, "rgba(0, 255, 0, 0)");

this.gradientYellow.addColorStop(0, "rgba(0, 255, 231, 0.9)");
this.gradientYellow.addColorStop(0.5, "rgba(0, 255, 231, 0.25)");
this.gradientYellow.addColorStop(1, "rgba(0, 255, 231, 0)");


},
    timeRangeMeter: function () {
      this.fetchMeterValues();
    },
    setChartType: function () {
      if (this.chartType == "AreaChart") {
        this.chartType = "ColumnChart";
      } else {
        this.chartType = "AreaChart";
      }
    },
    fetchMeterValues: function () {
      const requestOptions = {
        method: "POST",
        headers: {
          "x-access-token": this.$store.state.token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ range: this.misc.range[this.misc.rangeTick-1] }),
      };

      fetch(
        location.protocol + "//" + this.misc.hostname + "/api/v2/meter/get",
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          this.setChartGradient();
          this.meter = this.modifyForChartJs(data);
        })
        .catch((e) => {
          this.misc["snackbarText"] = "Error " + e;
          this.misc["snackbar"] = true;
        });
    },
    save(date) {
      console.log(date);
      this.$refs.menu.save(date);
    },
    checkHost: function () {
      console.log(location.host);
      if (
        location.host == "badboard.badcloud.eu" ||
        location.host == "test.badcloud.eu"
      ) {
        this.$store.state.globalData.hostname = location.host;
        this.misc.hostname = this.$store.state.globalData.hostname;
      } else {
        this.$store.state.globalData.hostname = "192.168.0.106:7000";
        this.misc.hostname = this.$store.state.globalData.hostname;
      }
      console.log(this.$store.state.globalData.hostname);
    },
  },
  watch: {
    sliderValueWatch: function (a, b) {
      console.log(a, b, this.misc.switchValue);
    },
    menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
    range: function (a, b) {
      console.log(a, b);
      this.timeRangeMeter();
    },
  },
  mounted() {
    //store temp input variables
    this.meterInput = this.$store.state.globalData.counter.meterInput;
    this.checkHost(); this.fetchMeterValues();
  },
  components: {
    AreaChart,
    //VDatePicker
  },
};
</script>


